<template>
  <div>
    <div class="d-flex flex-row justify-content-between mb-1">
      <div class="d-flex w-50">
        <b-form-datepicker
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          locale="ar"
          class="mr-2"
          size="lg"
          v-model="from"
          placeholder="من"
        ></b-form-datepicker>
        <b-form-datepicker
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          locale="ar"
          class="mr-2"
          size="lg"
          v-model="to"
          placeholder="إلى"
        ></b-form-datepicker>
        <b-button variant="primary" @click="FilterOutputs()">
          <feather-icon icon="SearchIcon"></feather-icon>
        </b-button>
      </div>
      <div>
        <b-button variant="primary" @click="DownloadData()">
          <feather-icon icon="FileTextIcon" size="16" />
          طباعة تقرير كامل
        </b-button>
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      :key="tableRenderKey"
      :columns="columns"
      :rows="this.custodies"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label == 'التفاصيل'">
          <span>التفاصيل</span>
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <!-- Column: Price -->
        <span v-if="props.column.field === 'price'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.price }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'" style="">
          <button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            style="margin-right: 16px"
            class="btn btn-danger"
            @click="$bvModal.show(props.row.id.toString())"
          >
            <feather-icon icon="Trash2Icon" size="12" />
          </button>
          <b-modal :id="props.row.id.toString()" centered header="test" header-class="justify-content-center" title="تأكيد الحذف" hide-footer>
            <div class="col-12 text-center">
              <p>
                <strong>هل انت متأكد من الحذف</strong>
              </p>
              <b-button variant="primary" size="sm" class="mt-2 mr-2" @click="DeleteOutput(props.row.id) + $bvModal.hide(props.row.id.toString())"
                >تأكيد</b-button
              >
              <b-button variant="danger" size="sm" class="mt-2 ml-2" @click="$bvModal.hide(props.row.id.toString())">إلغاء</b-button>
            </div>
          </b-modal>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select v-model="pageLength" :options="['3', '7', '10', '15', '20']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from '../code'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker
  },

  directives: {
    'b-modal': VBModal,
    Ripple
  },
  created() {
    this.FilterOutputs()
  },
  data() {
    return {
      custodies: [],
      pageLength: 7,
      currentPage: 1,
      totalCount: 0, // Total rows/items count from API
      totalPages: 0, // Total pages count from API
      dir: false,
      codeBasic,
      columns: [
        {
          label: 'المبلغ',
          field: 'price'
        },
        {
          label: 'البيان',
          field: 'details'
        },
        {
          label: 'التاريخ',
          field: 'date'
        },

        {
          label: 'التفاصيل',
          field: 'action',
          width: '28%'
        }
      ],
      from: '',
      to: '',
      searchTerm: '',
      tableRenderKey: 0
    }
  },
  methods: {
    async onPerPageChange(newPerPage) {
      // Update pageLength and refresh data for the current page
      this.pageLength = parseInt(newPerPage, 10)
      await this.FilterOutputs()
    },
    async handlePageChange() {
      // Fetch data for the current page
      await this.FilterOutputs()
    },
    async FilterOutputs() {
      if (this.from === '' || this.to === '') {
        let today = new Date()
        today.setHours(0, 0, 0, 0) // Set time to 12:00 AM
        this.from = today
        let nextDay = new Date()
        nextDay.setDate(nextDay.getDate() + 1) // Add 1 day
        nextDay.setHours(0, 0, 0, 0) // Set time to 12:00 AM
        this.to = nextDay
      }
      const payload = {
        startDate: this.from,
        endDate: this.to,
        page: this.currentPage,
        pageSize: this.pageLength,
        agentId: this.$route.params.id
      }

      try {
        const response = await this.$store.dispatch('GetAgentCustodyHistory', payload)
        if (response.data.isSuccess) {
          this.custodies = response.data.result
          this.tableRenderKey++
          this.totalCount = response.data.totalCountData
          this.totalPages = response.data.pagesCount
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async DownloadData() {
      // Check if either from or to is falsy

      if (this.custodies.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'لا يوجد بيانات للطباعة',
            icon: 'InfoIcon',
            variant: 'warning'
          }
        })
        return // End the function if either from or to is falsy
      }

      const that = this
      that.jsonData = that.custodies
      for (var i = 0; i < that.jsonData.length; i++) {
        delete that.jsonData[i].id
        delete that.jsonData[i].agentId
        delete that.jsonData[i].details
        Object.keys(that.jsonData[i]).forEach((key, index) => {
          that.jsonData[i][that.columns[index].label] = that.jsonData[i][key]
          delete that.jsonData[i][key]
        })
      }
      const data = XLSX.utils.json_to_sheet(that.jsonData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'تقرير-عهد-المندوب.xlsx')
    },

    async DeleteOutput(id) {
      try {
        const response = await this.$store.dispatch('RemoveSafeOutput', id)
        if (response.data.isSuccess) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })

          window.location.reload()
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style lang="scss" scoped>
.date {
  max-width: 400px;
  padding: 1;
  margin-right: 9vh;
  margin-bottom: 4vh;
}
.vgt-left-align {
  max-width: 100px;
}

::v-deep .vs__dropdown-toggle {
  padding: 0.5rem;
}
::v-deep .dropdown-menu {
  width: 270px;
  z-index: 9999;
}
::v-deep [dir] .vs__dropdown-toggle {
  padding: 0 0 4px;
  background: none;
  border-radius: 4px;
  padding: 0.4rem;
}
</style>
